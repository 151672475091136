import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "Our Offices",
  subheading = "Locations",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      title: "Secure",
      description:
        "In an era where digital threats abound, securing your business is non-negotiable. At [Your Company Name], we prioritize the safety and integrity of your digital assets. Our comprehensive security solutions ensure that your data, applications, and online presence are fortified against evolving cyber threats.",
    },
    {
      title: "24/7 Support",
      description:
        "We understand that your business doesn't sleep, and neither do we. Our commitment to your success goes beyond business hours with our round-the-clock 24/7 support. Experience peace of mind knowing that whenever you need assistance, our dedicated support team is just a call or message away.",
    },
    {
      title: "Reliable",
      description:
        "Reliability isn't just a promise; it's our commitment to your success. We understand that in the fast-paced world of business, you need solutions you can trust. That's why we pride ourselves on delivering reliable services that form the bedrock of your digital journey.",
    },
    {
      title: "Easy",
      description:
        "Simplicity meets excellence. We believe that navigating the digital landscape should be easy, intuitive, and stress-free. Our commitment to user-friendly solutions ensures that whether you're exploring our mobile apps, websites, or any other services, a seamless and enjoyable experience awaits you.",
    },
    {
      title: "Customizable",
      description:
        "Your digital needs are as unique as your business, and our commitment is to provide solutions that adapt to your vision. With our customizable services, you have the freedom to shape your digital landscape precisely the way you want it.",
    },
    {
      title: "Fast",
      description:
        "Time is of the essence, We understand the need for speed. Our commitment to delivering fast and efficient solutions sets us apart. From rapid deployment to quick response times, we ensure that your digital journey moves at the pace of your business.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
