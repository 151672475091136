import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Serivces</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
        description = { <>
          We provide an unparalleled experience marked by excellence, dedication, and a commitment to going above and beyond. Our amazing service is the cornerstone of our identity, ensuring that your journey with us is nothing short of exceptional.
          </>
        }
      />
      {
      // <MainFeature
      //   subheading={<Subheading>Quality Work</Subheading>}
      //   imageSrc={heroScreenshotImageSrc}
      //   imageBorder={true}
      //   imageDecoratorBlob={true}
      // />
      }
      {
      // <FeatureWithSteps
      //   subheading={<Subheading>STEPS</Subheading>}
      //   heading={
      //     <>
      //       Easy to <HighlightedText>Get Started.</HighlightedText>
      //     </>
      //   }
      //   textOnLeft={false}
      //   imageSrc={macHeroScreenshotImageSrc}
      //   imageDecoratorBlob={true}
      //   decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      // />
      }
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        description={<>Our journey is defined by a steadfast commitment to values that form the foundation of everything we do. We believe in building more than just solutions; we strive to cultivate relationships and experiences that embody our core principles—integrity, innovation, and excellence.</>}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We believe that quality solutions should be accessible to all. Our commitment to affordability is not just a promise; it's a fundamental part of our mission. Discover how we bring exceptional value to your doorstep without compromising on quality.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We understand that your business deserves nothing less than the highest level of professional service. Discover how we bring a commitment to excellence and unwavering professionalism to every aspect of our partnership.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      {
//        <Pricing
//         subheading={<Subheading>Pricing</Subheading>}
//         heading={
//           <>
//             Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
//           </>
//         }
//         plans={[
//           {
//             name: "Personal",
//             price: "$17.99",
//             duration: "Monthly",
//             mainFeature: "For Individuals",
//             features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
//           },
//           {
//             name: "Business",
//             price: "$37.99",
//             duration: "Monthly",
//             mainFeature: "For Small Businesses",
//             features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
//             featured: true
//           },
//           {
//             name: "Enterprise",
//             price: "$57.99",
//             duration: "Monthly",
//             mainFeature: "For Large Companies",
//             features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
//           }
//         ]}
//       />
}
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        description="Our success is measured not just by the services we provide, but by the love and satisfaction our clients express. Discover why our clients rave about their experiences with us and how we've turned partnerships into lasting relationships."
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      />
      {
      // <FAQ
      //   subheading={<Subheading>FAQS</Subheading>}
      //   heading={
      //     <>
      //       You have <HighlightedText>Questions ?</HighlightedText>
      //     </>
      //   }
      //   faqs={[
      //     {
      //       question: "Are all the templates easily customizable ?",
      //       answer:
      //         "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      //     },
      //     {
      //       question: "How long do you usually support an standalone template for ?",
      //       answer:
      //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      //     },
      //     {
      //       question: "What kind of payment methods do you accept ?",
      //       answer:
      //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      //     },
      //     {
      //       question: "Is there a subscribption service to get the latest templates ?",
      //       answer:
      //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      //     },
      //     {
      //       question: "Are the templates compatible with the React ?",
      //       answer:
      //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      //     },
      //     {
      //       question: "Do you really support Internet Explorer 11 ?",
      //       answer:
      //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      //     }
      //   ]}
      // />
      }
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
